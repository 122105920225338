
import React from 'react'
import FormatDate from 'src/utils/FormatDate'
import Label from 'src/components/Label'
import FluidImage from 'src/utils/FluidImage'
import Heading from 'src/components/Heading'
import Wave from 'src/utils/Wave'

const ContentHeadingCentered = (props) => {
    return (
        <div className="relative">
            <div className={`2xl:pt-40 xl:pt-36 pt-32 pb-40 px-6 bg-blue`}>
                    <Label className="text-teal text-center">{FormatDate(props.first_published_at)} · {props.content.component.replace(/-/g, ' ').replace(/_/g, ' ')}</Label>
                    <Heading size="h1" className="text-white text-center lg:mt-8 mt-6 max-w-[813px] mx-auto !text-3xl lg:!text-4.5xl !tracking-[-0.02em]">{props.name}</Heading>
                    <p className="text-darkblue-400 lg:text-base text-sm tracking-[-0.01em] max-w-3xl mx-auto text-center lg:mt-8 mt-6">{props.content.description}</p>
            </div>
            <Wave size="small" className="fill-blue"/>
            <div className="px-6 rounded-md">
                <FluidImage className="max-w-[813px] 2xl:-mt-44 xl:-mt-40 lg:-mt-36 md:-mt-36 -mt-32 mx-auto rounded-md" image={props.content.featured_image}/>
            </div>
        </div>
    )
  };

export default ContentHeadingCentered