import React from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from "src/utils/context";
import SetCookie from "src/utils/SetCookie";
import ContentHeadingCentered from "src/components/ContentHeadingCentered";
import GetCookie from "../utils/GetCookie";
import LanguageSelector from "../components/LanguageSelector";

export default class PageTemplate extends React.Component {
  state = {
    story: {
      name: this.props.data.story ? this.props.data.story.name : "",
      content: this.props.data.story
        ? JSON.parse(this.props.data.story.content)
        : {},
    },
    cta_referrer: null,
  };

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);
    let {
      data: { story },
    } = await StoryblokService.get(
      `cdn/stories/${this.props.data.story.full_slug}`
    );
    return story;
  }

  async componentDidMount() {
    let story = await this.getInitialStory();
    if (story.content) this.setState({ story });
    setTimeout(() => StoryblokService.initEditor(this), 200);
    if (this.getCtaReferrer()) {
      this.setCtaReferrer(this.getCtaReferrer());
    }
  }

  getCtaReferrer = () => {
    return GetCookie("cta_referrer");
  };

  setCtaReferrer = (value) => {
    SetCookie(`cta_referrer=${value}`);
    this.setState({ cta_referrer: value });
  };

  render() {
    const toggleContentHeadingCentered = [
      "resource",
      "webinar",
      "success_story",
      "whitepaper",
      "blog",
      "news",
      "insight",
    ].includes(this.state.story.content.component);
    return (
      <PageContext.Provider
        value={{
          hasHero:
            this.state.story.content.body &&
            ["hero", "hero-custom"].includes(
              this.state.story.content.body[0]?.component
            )
              ? true
              : toggleContentHeadingCentered
              ? false
              : false,
          currentPage: this.state.story.uuid,
          breadcrumb: this.props.pageContext.breadcrumb,
          location: this.props.location,
          name: this.state.story.name,
          first_published_at: this.state.story.first_published_at,
          content: this.state.story.content,
          setCtaReferrer: this.setCtaReferrer,
          ctaReferrer: this.state.cta_referrer,
          parent_id: this?.state?.story?.parent_id,
        }}
      >
        <Layout location={this.props.location}>
          {toggleContentHeadingCentered && (
            <ContentHeadingCentered {...this.state.story} />
          )}
          <Page blok={this.state.story.content} name={this.state.story.name} />
         
        </Layout>
      </PageContext.Provider>
    );
  }
}

export const query = graphql`
  query storyBySlug($fullSlug: String!) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      name
      content
      full_slug
      uuid
      first_published_at
    }
  }
`;
