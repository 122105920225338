import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const LanguageSelector = () => {
  const [language, setLanguage] = useState("en");
  const [enPath, setEnPath] = useState("");
  const [esPath, setEsPath] = useState("");

  useEffect(() => {
    if (window !== "undefined") {
      const url = new URL(window.location.href);
      setLanguage(
        url.pathname.split("/")[1] === "blog" ||
          url.pathname.split("/")[1] === "insights"
          ? "en"
          : "es"
      );
      setEnPath(
        url.pathname.split("/")[1] === "blog" ||
          url.pathname.split("/")[1] === "insights"
          ? url.pathname
          : url.pathname.replace("/es", "")
      );
      setEsPath(
        url.pathname.split("/")[1] === "blog" ||
          url.pathname.split("/")[1] === "insights"
          ? "/es" + url.pathname
          : url.pathname
      );
    }
  }, [setLanguage, setEnPath, setEsPath]);

  return (
    <div className="fixed right-0 bottom-0 z-[200] p-5">
      <div className="group bg-teal max-w-[90px] overflow-hidden rounded-md duration-300 hover:max-w-[200px]">
        <div className={`flex flex-row items-center justify-between`}>
          <Link
            to={enPath}
            className={`flex w-[90px] cursor-pointer items-center py-4 px-6 text-sm font-bold leading-4 text-white duration-300 ${
              language === "en" ? "order-1 opacity-100" : "order-2 opacity-50"
            }`}
          >
            <span class="fi fi-gb fi mr-1"></span> EN
          </Link>
          <Link
            to={esPath}
            className={`flex w-[90px] cursor-pointer items-center py-4 px-6 text-sm font-bold leading-4 text-white duration-300 ${
              language === "es" ? "order-1 opacity-100" : "order-2 opacity-50"
            }`}
          >
            <span class="fi fi-es fi mr-1"></span> ES
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
